<template>
  <div class="surname-variant-details-page">
    <page-not-found v-if="surnameNotFound"></page-not-found>
    <div class="readable_content" v-else>
      <div class="title-wrapper">
        <h1>{{ surname }} Chinese Last Name Facts</h1>
        <share-url-button :route="shareRoute" :title="shareTitle" :track-params="shareTrackParams"></share-url-button>
      </div>

      <cta-unified-search :surname="surname" :surname-first="false" :paywall="true"></cta-unified-search>

      <h5>{{ surname }} Surname Meaning</h5>
      <section-description
        v-if="showDescription"
        :surname="surname"
        :description="spellingDetailedState.description"
        :clans="surnameClansSearchListState"
        :places="spellingDetailedState.places"
        :dialects="spellingDetailedState.dialects_list"
        :is-template="isTemplate"
      ></section-description>
      <template v-else-if="loading">
        <loading-animated class="description-loading"></loading-animated>
        <loading-animated class="mobile-only description-loading"></loading-animated>
      </template>

      <template v-if="spellingDetailedState">
        <section-related-surnames
          v-if="spellingDetailedState.related_surnames.length"
          class="sub-section"
          :surnames="spellingDetailedState.related_surnames"
          :is-template="isTemplate"
        ></section-related-surnames>
        <section-related-dialects
          v-if="spellingDetailedState.dialects_list.length"
          class="sub-section"
          :dialects="spellingDetailedState.dialects_list"
          :is-template="isTemplate"
        ></section-related-dialects>
        <section-related-regions
          v-if="spellingDetailedState.places.length"
          class="sub-section"
          :places="spellingDetailedState.places"
          :is-template="isTemplate"
        ></section-related-regions>
      </template>
      <loading-animated v-else-if="loading" class="related-items-loading"></loading-animated>

      <section-clans class="section" :surname="surname"></section-clans>

      <section-zupus
        v-if="sectionRecordZupusCount"
        :pinyin="surname"
        :clan-name="surname"
        :count="sectionRecordZupusCount"
        class="section"
      ></section-zupus>

      <section-records
        class="section"
        :pinyin="surname"
        :meta="searchRecordsMeta"
        :loading="searchCategoriesLoadingState"
        :surname="surname"
      ></section-records>

      <section-places-map
        v-if="mapPlaces.length"
        class="section"
        :places="mapPlaces"
        :all-places="spellingDetailedState.places"
        :pinyin="surname"
        :is-template="isTemplate"
      ></section-places-map>

      <section-places class="section" :pinyin="surname" :places="ancestralPlaces"></section-places>

      <cta-block-compare-plans
        class="section"
        action="Dig Deeper"
        :title="comparePlansTitle"
        :track-title="comparePlansTrackTitle"
      ></cta-block-compare-plans>

      <div class="action-block new-search">
        <surname-search-form-block></surname-search-form-block>
      </div>
    </div>
  </div>
</template>

<script>
import SurnameSearchFormBlock from '@common/elements/forms/SurnameSearchFormBlock';
import LoadingAnimated from '@common/elements/loading/LoadingAnimated';
import SectionClans from '@common/pages/surnameDetails/SectionClans';
import SectionDescription from '@common/pages/surnameDetails/SectionDescription';
import SectionPlaces from '@common/pages/surnameDetails/SectionPlaces';
import SectionPlacesMap from '@common/pages/surnameDetails/SectionPlacesMap';
import SectionRecords from '@common/pages/surnameDetails/SectionRecords';
import SectionRelatedDialects from '@common/pages/surnameDetails/SectionRelatedDialects';
import SectionRelatedRegions from '@common/pages/surnameDetails/SectionRelatedRegions';
import SectionRelatedSurnames from '@common/pages/surnameDetails/SectionRelatedSurnames';
import SectionZupus from '@common/pages/surnameDetails/SectionZupus.vue';
import variantDetailsPage from '@common/pages/surnameDetails/js/variant-details-page';
import {isChineseText} from '@common/utils/utils';
import {getSurnameDetailRoute} from '@common/utils/utils.routes';
import capitalize from 'lodash/capitalize';
import {mapGetters} from 'vuex';

import ShareUrlButton from '@/components/common/buttons/ShareUrlButton';
import CtaBlockComparePlans from '@/components/common/cta/ctaBlockComparePlans';

import {FORM_TYPES} from '@/components/modules/contactUs/constants';
import CtaUnifiedSearch from '@/components/modules/surnameDetails/common/ctaUnifiedSearch';

import clansData from './prerender/clansData';
import spellingData from './prerender/spellingData';

const PageNotFound = () => import('@/components/page.not.found');

export default {
  metaInfo() {
    return variantDetailsPage.getMetaInfo(this);
  },
  created() {
    this.clearClanVariantDetails();
    if (this.$route.meta.isPrerenderTemplate) {
      this.isTemplate = true;
      this.$store.commit('setSpellingDetailedState', spellingData);
      this.$store.commit('setSurnameClansSearchListState', clansData);
      return;
    }
    if (window.__PRERENDERED_STATE__) {
      this.$store.commit('setSpellingDetailedState', window.__PRERENDERED_STATE__.spelling);
      this.$store.commit('setSurnameClansSearchListState', window.__PRERENDERED_STATE__.clans);
      window.__PRERENDERED_STATE__ = null;
      return;
    }
    this.init();
    if (this.userIsLoggedInState) {
      this.$store.dispatch('fetchFamilyTreePersonAction', 'my');
    }
  },
  watch: {
    '$route.query': {
      handler: function (toQuery, fromQuery) {
        if (toQuery.word !== fromQuery.word) {
          this.clearClanVariantDetails();
          this.init();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      isTemplate: false,
      surnameNotFound: false,
      contactFormType: FORM_TYPES.ancestor,
      searchRecordsMeta: {},
    };
  },
  computed: {
    ...mapGetters([
      'spellingDetailedState',
      'userIsLoggedInState',
      'spellingDetailedLoadingState',
      'surnameClansSearchLoadingState',
      'surnameClansSearchListState',
      'familyTreePersonState',
      'userIsSharedState',
      'featureSwitchesLoadedState',
      'featureSwitchesState',
      'searchCategoriesLoadingState',
    ]),
    pageTitle() {
      const surname = this.isTemplate ? this.spellingDetailedState.name : capitalize(this.$route.query.word);
      return `${surname} Chinese Last Name Facts`;
    },
    surname() {
      return this.spellingDetailedState ? this.spellingDetailedState.name : this.$route.query.word;
    },
    surnamesToSave() {
      return [this.surname];
    },
    hasEnglishSurname() {
      return this.familyTreePersonState
        ? Boolean(this.familyTreePersonState.surnames.find(surname => !isChineseText(surname.value)))
        : false;
    },
    ancestorData() {
      return {relation: 'Grandfather', firstName: '', lastName: this.surnamesToSave.join(' ')};
    },
    showDescription() {
      return Boolean(
        this.spellingDetailedState && this.surnameClansSearchListState && this.surnameClansSearchListState.length
      );
    },
    loading() {
      return this.spellingDetailedLoadingState || this.surnameClansSearchLoadingState;
    },
    ancestralPlaces() {
      return this.spellingDetailedState ? this.spellingDetailedState.ancestral_places : [];
    },
    shareRoute() {
      return getSurnameDetailRoute(this.$route.query.word);
    },
    shareTitle() {
      return `Check out this page I found on the meaning and origins of the surname "${this.$route.query.word}": `;
    },
    shareTrackParams() {
      return {surname: this.$route.query.word};
    },
    comparePlansTitle() {
      return `What ${this.surname} family history discoveries await you?`;
    },
    comparePlansTrackTitle() {
      return this.comparePlansTitle.replace(this.surname, '[NAME]');
    },
    sectionRecordZupusCount() {
      if (this.isTemplate) {
        return 0;
      }
      return variantDetailsPage.getSectionRecordZupusCount(this);
    },
    mapPlaces() {
      if (this.spellingDetailedState && this.spellingDetailedState.places.length) {
        return this.spellingDetailedState.places.filter(item => item.official_code);
      }
      return [];
    },
  },
  methods: {
    init() {
      return variantDetailsPage.init(this);
    },
    clearClanVariantDetails() {
      return variantDetailsPage.clearClanVariantDetails(this);
    },
  },
  components: {
    SectionZupus,
    CtaBlockComparePlans,
    ShareUrlButton,
    SectionPlaces,
    CtaUnifiedSearch,
    PageNotFound,
    LoadingAnimated,
    SectionClans,
    SectionDescription,
    SectionRecords,
    SectionRelatedRegions,
    SectionRelatedDialects,
    SectionRelatedSurnames,
    SurnameSearchFormBlock,
    SectionPlacesMap,
  },
};
</script>

<style lang="scss" scoped>
@import '~@common/pages/surnameDetails/styles/variant-details-page.scss';
</style>
