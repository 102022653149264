<template>
  <div class="dialect-page">
    <div v-if="notFound">
      <slot name="page-not-found"></slot>
    </div>
    <div v-else-if="dialectLoadingState" class="readable_content">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
    <div v-else class="readable_content">
      <div class="title-wrapper">
        <h1>{{ mainTitle }}</h1>
        <share-url-button :route="shareRoute" :title="shareTitle" :track-params="shareTrackParams"></share-url-button>
      </div>
      <div class="section">
        <h5>About the {{ dialectState.name }} Dialect</h5>
        <div v-html="dialectState.description"></div>
        <div class="section-related" v-if="dialectState.est_native_speakers">
          <strong>Native Speakers: </strong> {{ dialectState.est_native_speakers }}
        </div>
        <section-related
          v-if="dialectState.ancestral_provinces.length"
          :items="dialectState.ancestral_provinces"
          title="Ancestral Places: "
        >
          <template v-slot:item="{item}">
            <router-link :to="getPlaceDetailRoute(item.id, item.pinyin)">{{ item.pinyin }}</router-link>
          </template>
        </section-related>
        <section-related
          v-if="dialectState.overseas_countries.length"
          :items="dialectState.overseas_countries"
          :title="relatedOverseasPlacesTitle"
        >
          <template v-slot:item="{item}">
            <router-link :to="getPlaceDetailRoute(item.id, item.pinyin)">{{ item.pinyin }}</router-link>
          </template>
        </section-related>
      </div>
      <div class="section">
        <h5>{{ dialectState.name }} Surnames</h5>
        <div>Below is a list of common spellings of surnames in the {{ dialectState.name }} dialect.</div>
        <div class="surnames-container" :class="{trimmed: !showAllSurnames}">
          <div v-for="item in shownSurnames">
            <router-link :to="getSurnameDetailRoute(item)" rel="nofollow">{{ item }}</router-link>
          </div>
        </div>
        <a v-if="hasMoreSurnames && !showAllSurnames" class="divider" @click="showAllSurnamesClick">View all</a>
      </div>
      <div class="section" v-if="$slots.cta">
        <slot name="cta"></slot>
      </div>

      <section-external-links
        class="section"
        v-if="dialectState.external_links.length"
        :links="dialectState.external_links"
      ></section-external-links>
      <div class="section">
        Interested in other dialects?
        <router-link :to="dialectsWikiRoute">Discover all varieties of spoken Chinese.</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SectionExternalLinks from '@common/elements/layouts/SectionExternalLinks';
import SectionRelated from '@common/elements/layouts/SectionRelated.vue';
import {getDialectDetailRoute, getPlaceDetailRoute, getSurnameDetailRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

import ShareUrlButton from '@/components/common/buttons/ShareUrlButton';

export default {
  metaInfo() {
    return {
      title: this.mainTitle,
    };
  },
  data() {
    return {
      notFound: false,
      showAllSurnames: false,
      surnamesTrim: 20,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters(['dialectState', 'dialectLoadingState']),
    mainTitle() {
      return this.dialectState.name ? `${this.dialectState.name} Dialect Facts` : 'Dialect';
    },
    shareRoute() {
      return getDialectDetailRoute(this.$route.params.slug);
    },
    shareTitle() {
      return `Check out this page I found on the dialect "${this.dialectState.name}": `;
    },
    shareTrackParams() {
      return {name: this.dialectState.name};
    },
    relatedOverseasPlacesTitle() {
      return `Overseas Communities: `;
    },
    dialectsWikiRoute() {
      return {name: 'wiki-article', params: {slug: 'varieties-of-spoken-chinese'}};
    },
    shownSurnames() {
      if (this.showAllSurnames) {
        return this.dialectState.surnames;
      }
      return this.dialectState.surnames.slice(0, this.surnamesTrim);
    },
    hasMoreSurnames() {
      return !this.showAllSurnames && this.dialectState.surnames.length > this.surnamesTrim;
    },
  },
  methods: {
    getSurnameDetailRoute,
    getPlaceDetailRoute,
    init() {
      this.$store.dispatch('fetchDialectAction', {slug: this.$route.params.slug}).catch(() => {
        this.notFound = true;
      });
    },
    showAllSurnamesClick() {
      this.showAllSurnames = true;
    },
  },
  components: {SectionExternalLinks, SectionRelated, ShareUrlButton},
  name: 'DialectBasePage',
};
</script>

<style scoped lang="scss">
.dialect-page {
  min-height: 100vh;
  h1 {
    margin-bottom: 0;
  }

  .title-wrapper {
    display: flex;
    border-bottom: 1px solid $divider-line-color;
    padding-bottom: 20px;
    margin-bottom: 16px;
    align-items: flex-end;
    justify-content: space-between;

    .title {
      margin-right: 15px;
    }
  }

  .section {
    margin-top: 36px;
  }
  .section-related {
    margin-top: 16px;
  }

  .surnames-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px 100px;
    padding: 16px 0;

    @media only screen and (max-width: $breakpoint-mobile) {
      grid-template-columns: repeat(2, 1fr);
      &.trimmed {
        max-height: 210px;
        overflow: hidden;
      }
    }
  }
}
</style>
