<template>
  <stand-out-block class="cta-unified-search">
    <h5>
      Discover records of {{ surname }}<span v-if="altSurname"> ({{ altSurname }})</span> ancestors and create your
      family tree
    </h5>
    <search-all-records-form
      :surname-first="surnameFirst"
      :surname-init="surnameInit"
      :smart-redirect="false"
      :paywall="paywall"
      button-label="Find records"
      class="sub-section"
      @language-error="onLanguageError"
      @before-redirect="trackFormSubmit"
    ></search-all-records-form>
    <div v-if="error" class="error">{{ error }}</div>
  </stand-out-block>
</template>

<script>
import SearchAllRecordsForm from '@common/elements/forms/SearchAllRecordsForm';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {isChineseText} from '@common/utils/utils';

export default {
  props: {
    surname: String,
    altSurname: String,
    prefillSurname: {type: Boolean, default: true},
    surnameFirst: Boolean,
    paywall: {type: Boolean, default: false},
  },
  data() {
    return {
      error: '',
      surnameInit: this.prefillSurname ? this.surname : '',
    };
  },
  methods: {
    onLanguageError() {
      this.error = 'Please use the same language for both first and last names.';
    },
    trackFormSubmit() {
      if (isChineseText(this.surname)) {
        AnalyticsAmplitudeHandler.trackSurnameRecordsSearchSubmit(this.surname, 'CN');
      }
    },
  },
  components: {StandOutBlock, SearchAllRecordsForm},
  name: 'ctaUnifiedSearch',
};
</script>

<style lang="scss" scoped>
.cta-unified-search {
  .error {
    margin-top: 10px;
  }
}
</style>
